
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Injectable } from '@angular/core';
// import WalletConnect from "@walletconnect/client";
// import QRCodeModal from "@walletconnect/qrcode-modal";

import { BehaviorSubject, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
interface Chain {
  id: number;
  name: string;
  currency: string;
}

@Injectable({
  providedIn: 'root'
})
export class NftService {


  public walletConnectNftStatusSubject = new BehaviorSubject(null);
  private _state;
  public connector;
  public isWalletConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public openseaAPIEndpoint = 'https://qliktag.engine-usw2.thirdweb.com'; //https://testnets-api.opensea.io/api/v1/
  public thirdwebAPIEndpoint = 'https://qliktag.engine-usw2.thirdweb.com'
  private _walletAddress = null
 // private nftApiKey = environment.nftApiKey;
  private thirdwebToken ='eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiIweGY1ZjRmNzQ2NDkzRTgyZDk2MDExNDkzMEQwZjNhNkU5NDQ1RjM0OEIiLCJzdWIiOiIweERhMUE0OTQ1ODBDM0JjOEVCZDI4QzNiNDI1ZDk3YTRmMTg2NTkzM2IiLCJhdWQiOiJ0aGlyZHdlYi5jb20iLCJleHAiOjQ4NjUzMjMzMDUsIm5iZiI6MTcxMTcyMzMwNSwiaWF0IjoxNzExNzIzMzA1LCJqdGkiOiI4YzM3NzZjMS1iZmVlLTQ1ZjItOTMwNS02ZDAzZjc3NTYyODAiLCJjdHgiOnsicGVybWlzc2lvbnMiOiJBRE1JTiJ9fQ.MHg5ZDFhNmZkN2U3YjM2ZjA2NGQ5YzcwMjc5YjYxM2Y3YzI2MTlhOTU5MmEzMjJlMTFlOTczYzNkNGYyZDY2OWQ2NTExOGU4YzE5MDIzNjRhMGRkOGFlYzk2MDQ0NDBjZGRlN2E0OGRiMjAyMTZiZGExYWI4OGZkYzhhNDE3YmY3ZDFi'
    
  chainList: Chain[] = [
    { id: 1, name: 'ethereum', currency: 'ETH' },
    { id: 2, name: 'bsc', currency: 'BNB' },
    { id: 42161, name: 'arbitrum', currency: 'ETH' },
    { id: 137, name: 'polygon', currency: 'matic' },
    { id: 11155111, name: 'sepolia', currency: 'ETH' },
    { id: 84532, name:'basesepoliatestnet', currency:'ETH'}
    // { id: 1, name: 'solano', currency: 'ETH' },
    // { id: 1, name: 'flow', currency: 'ETH' },
  ];

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = { ...state };
  }

 

  get walletAddress() {
    return this._walletAddress;
  }

  set walletAddress(addr) {
    this._walletAddress = addr;
  }

  setWalletConnectNftStatus(state: boolean) {
    this.walletConnectNftStatusSubject.next(state);
  }

  getWalletConnectNftStatusObservable() {
    return this.walletConnectNftStatusSubject.asObservable();
  }

  getWalletConnectNftStatus() {
    return this.walletConnectNftStatusSubject.getValue();
  }


  constructor(private apiService: ApiService,private http: HttpClient) {}

  /**
   * Initialize WalletConnect v1
   * @author Neil C
   */
  // init() {

  //   this.openseaAPIEndpoint = `https://testnets-api.opensea.io/api/v2/`;





  // }






  /**
   * 
   * @param asset_contract_address 
   * @param token_id 
   * @returns 
   */
  getAsset(token_id: string, asset_contract_address: string, account_address: string, chainValue: any, tokenStandard:any) {
    this.registerDomainThirdWeb();
    let tokenId = token_id.replace(/"/g, "");
    let chainName = this.chainList.find(c => c.name.toLowerCase() === chainValue.toLowerCase());
    tokenStandard = tokenStandard.replace(/-/g, "").toLowerCase();
    let params;
    let path = '';
   // let token = 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiIweGY1ZjRmNzQ2NDkzRTgyZDk2MDExNDkzMEQwZjNhNkU5NDQ1RjM0OEIiLCJzdWIiOiIweERhMUE0OTQ1ODBDM0JjOEVCZDI4QzNiNDI1ZDk3YTRmMTg2NTkzM2IiLCJhdWQiOiJ0aGlyZHdlYi5jb20iLCJleHAiOjQ4NjUzMjMzMDUsIm5iZiI6MTcxMTcyMzMwNSwiaWF0IjoxNzExNzIzMzA1LCJqdGkiOiI4YzM3NzZjMS1iZmVlLTQ1ZjItOTMwNS02ZDAzZjc3NTYyODAiLCJjdHgiOnsicGVybWlzc2lvbnMiOiJBRE1JTiJ9fQ.MHg5ZDFhNmZkN2U3YjM2ZjA2NGQ5YzcwMjc5YjYxM2Y3YzI2MTlhOTU5MmEzMjJlMTFlOTczYzNkNGYyZDY2OWQ2NTExOGU4YzE5MDIzNjRhMGRkOGFlYzk2MDQ0NDBjZGRlN2E0OGRiMjAyMTZiZGExYWI4OGZkYzhhNDE3YmY3ZDFi'
    if(tokenStandard == 'erc1155'){
      params = new HttpParams().set('walletAddress', account_address).set('tokenId', tokenId);
      path = `${this.thirdwebAPIEndpoint}/contract/${chainName.id}/${asset_contract_address}/${tokenStandard}/balance-of`;
    }else if(tokenStandard == 'erc721'){
      params = new HttpParams().set('tokenId', tokenId);
      path = `${this.thirdwebAPIEndpoint}/contract/${chainName.id}/${asset_contract_address}/${tokenStandard}/get`;
    }
    
    return this.apiService.getByPath(path,params,this.thirdwebToken)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      )

  }

  registerDomainThirdWeb(){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.thirdwebToken}`
     });
    const payload = {
      "urlsToAdd": [
         window.location.origin
      ]
    };
    let path = `${this.thirdwebAPIEndpoint}/configuration/cors`;
    this.http.post(path,payload,{headers}).subscribe(
      response => {
        console.log('Response:', response);
      },
      error => {
        console.error('Error:', error);
      }
   );
  }

}
