export const COC_AGENCY = {
    'ca_prop_65':{
        'title': 'CA Prop 65',
        'description': 'Chemicals known to the State of California to cause cancer or reproductive toxicity that are listed pursuant to the Safe Drinking Water and Toxic Enforcement Act of 1986 (Chapter 6.6 (commencing with Section 25249.5 of Division 20)).',
        'url': 'https://oehha.ca.gov/proposition-65/proposition-65-list'
    },
    'eu_cmrs': {
        'title': 'EU CMRs',
        'description': 'Chemicals classified by the European Union as carcinogens, mutagens, or reproductive toxicants pursuant to Category 1A or 1B in Annex VI to Regulation (EC) 1272/2008.',
        'url': 'https://echa.europa.eu/documents/10162/13562/cmr_report_en.pdf'
    },
    'eu_endocrine_disruptors': {
        'title': 'EU Endocrine Disruptors',
        'description': 'Chemicals included in the European Union Candidate List of Substances of Very High Concern in accordance with Article 59 of Regulation (EC) 1907/2006 on the basis of Article 57(f) for endocrine disrupting properties.',
        'url': 'https://echa.europa.eu/candidate-list-table'
    },
    'iris_neuotoxicants': {
        'title': 'IRIS Neuotoxicants',
        'description': 'Chemicals for which a reference dose or reference concentration has been developed based on neurotoxicity in the federal Environmental Protection Agency’s Integrated Risk Information System.',
        'url': 'https://cfpub.epa.gov/ncea/iris/search/index.cfm?sys_joint=11'
    },
    'iris_carcinogens': {
        'title': 'IRIS Carcinogens',
        'description': 'Chemicals that are identified as carcinogenic to humans, likely to be carcinogenic to humans, or as Group A, B1, or B2 carcinogens in the federal Environmental Protection Agency’s Integrated Risk Information System.',
        'url': 'https://cfpub.epa.gov/ncea/iris/search/index.cfm?sys_joint=11'
    },
    'eu_pbts': {
        'title': 'EU PBTs',
        'description': 'Chemicals included in the European Chemicals Agency Candidate List of Substances of Very High Concern in accordance with Article 59 of Regulation (EC) 1907/2006 on the basis of Article 57(d), Article 57(e), or Article 57(f) of Regulation (EC) 1907/2006 for persistent, bioaccumulative and toxic, or very persistent and very bioaccumulative properties',
        'url': 'https://echa.europa.eu/candidate-list-table'
    },
    'canada_pbts': {
        'title': 'Canada PBTs',
        'description': 'Chemicals that are identified as persistent, bioaccumulative, and inherently toxic to the environment by the Canadian Environmental Protection Act Environmental Registry Domestic Substances List.',
        'url': 'https://www.canada.ca/en/environment-climate-change/services/canadian-environmental-protection-act-registry/substances-list/domestic/categorization-existing.html'
    },
    'eu_respiratory_sensitizers': {
        'title': 'EU Respiratory Sensitizers',
        'description': 'Chemicals classified by the European Union in Annex VI to Regulation (EC) 1272/2008 as respiratory sensitizer category 1.',
        'url': 'https://echa.europa.eu/information-on-chemicals/cl-inventory-database?p_p_id=dissclinventory_WAR_dissclinventoryportlet&p_p_lifecycle=0&p_p_state=normal&p_p_mode=view&p_p_col_id=column-1&p_p_col_pos=1&p_p_col_count=2'
    },
    'iarc_carcinogens': {
        'title': 'IARC Carcinogens',
        'description': 'Group 1, 2A, or 2B carcinogens identified by the International Agency for Research on Cancer',
        'url': 'https://monographs.iarc.fr/agents-classified-by-the-iarc/'
    },
    'atsdr_neurotoxicants': {
        'title': 'ATSDR Neurotoxicants',
        'description': 'Neurotoxicants that are identified in the federal Agency for Toxic Substances and Disease Registry’s Toxic Substances Portal, Health Effects of Toxic Substances and Carcinogens, Nervous System',
        'url': 'https://www.atsdr.cdc.gov/substances/toxorganlisting.asp?sysid=18'
    },
    'us_epa_priority_chemicals_list': {
        'title': 'US EPA Priority Chemicals List',
        'description': 'Persistent bioaccumulative and toxic priority chemicals that are identified by the federal Environmental Protection Agency National Waste Minimization Program',
        'url': 'https://archive.epa.gov/epawaste/hazard/wastemin/web/html/priority.html'
    },
    'us_ntp_reproductive_or_developmental_toxicants': {
        'title': 'US NTP Reproductive or Developmental Toxicants',
        'description': 'Reproductive or developmental toxicants identified in Monographs on the Potential Human Reproductive and Developmental Effects published by the federal National Toxicology Program, Office of Health Assessment and Translation',
        'url': 'https://ntp.niehs.nih.gov/pubhealth/hat/noms/evals.html'
    },
    'us_epa_pbts': {
        'title': 'US EPA PBTs',
        'description': 'Chemicals identified by the federal Environmental Protection Agency’s Toxics Release Inventory as Persistent, Bioaccumulative and Toxic Chemicals that are subject to reporting under Section 313 of the Emergency Planning and Community Right-to-Know Act of 1986 (42 U.S.C. Sec. 11001, et seq.).',
        'url': 'https://www.epa.gov/toxics-release-inventory-tri-program/persistent-bioaccumulative-toxic-pbt-chemicals-covered-tri'
    },
    'wa_pbts': {
        'title': 'WA PBTs',
        'description': 'The Washington Department of Ecology’s Persistent, Bioaccumulative, Toxic (PBT) Chemicals identified in Chapter 173-333 of Title 173 of the Washington Administrative Code.',
        'url': 'https://app.leg.wa.gov/wac/default.aspx?cite=173-333-310'
    },
    'us_ntp_carcinogens': {
        'title': 'US NTP Carcinogens',
        'description': 'Chemicals that are identified as known to be, or reasonably anticipated to be, human carcinogens by the 13th Report on Carcinogens prepared by the federal National Toxicology Program. Subsequent revisions to this list shall not be incorporated.',
        'url': 'https://ntp.niehs.nih.gov/pubhealth/roc/index.html'
    },
    'ca_nls': {
        'title': 'CA NLs',
        'description': 'Chemicals for which notification levels, as defined in Section 116455, have been established by the State Department of Public Health or the State Water Resources Control Board.',
        'url': 'https://www.waterboards.ca.gov/drinking_water/certlic/drinkingwater/documents/notificationlevels/notification_'
    },
    'ca_mcls': {
        'title': 'CA MCLs',
        'description': 'Chemicals for which primary maximum contaminant levels have been established and adopted under Section 64431 or 64444 of Title 22 of the California Code of Regulations.',
        'url': 'https://www.waterboards.ca.gov/drinking_water/certlic/drinkingwater/Lawbook.html'
    },
    'ca_tacs': {
        'title': 'CA TACs',
        'description': 'Chemicals identified as toxic air contaminants under Section 93000 or 93001 of Title 17 of the California Code of Regulations.',
        'url': 'https://ww3.arb.ca.gov/toxics/id/taclist.htm'
    },
    'ca_priority_pollutants': {
        'title': 'CA Priority Pollutants',
        'description': 'Chemicals that are identified as priority pollutants in the California water quality control plans pursuant to subdivision (c) of Section 303 of the federal Clean Water Act and in Section 131.38 of Title 40 of the Code of Federal Regulations, or identified as pollutants by the state or the federal Environmental Protection Agency for one or more water bodies in the state under subdivision (d) of Section 303 of the federal Clean Water Act and Section 130.7 of Title 40 of the Code of Federal Regulations.',
        'url': 'https://www.govinfo.gov/content/pkg/CFR-2010-title40-vol21/pdf/CFR-2010-title40-vol21-sec131-38.pdf'
    },
    'ca_non-cancer_hazards': {
        'title': 'CA Non-Cancer Hazards',
        'description': 'Chemicals that are identified with noncancer endpoints and listed with an inhalation or oral reference exposure level by the Office of Environmental Health Hazard Assessment pursuant to paragraph (2) of subdivision (b) of Section 44360.',
        'url': 'https://oehha.ca.gov/air/general-info/oehha-acute-8-hour-and-chronic-reference-exposure-level-rel-summary'
    },
    'ca_priority_chemicals': {
        'title': 'CA Priority Chemicals',
        'description': 'Chemicals identified as priority chemicals by the California Environmental Contaminant Biomonitoring Program pursuant to Section 105449.',
        'url': 'https://biomonitoring.ca.gov/chemicals/priority-chemicals'
    },
    'marine_priority_action_chemicals': {
        'title': 'Marine Priority Action Chemicals',
        'description': 'Chemicals that are identified on Part A of the list of Chemicals for Priority Action prepared by the Oslo and Paris Conventions for the Protection of the Marine Environment of the North-East Atlantic.',
        'url': 'https://www.ospar.org/work-areas/hasec/chemicals/priority-action'
    },
    'eu_fragrance_allergens': {
        'title': 'EU Fragrance Allergens',
        'description': 'Chemicals identified as fragrance allergens in Annex III of the EU Cosmetics Regulation 1223/2009, as required to be labeled by the European Detergents Regulation No. 648/2004.',
        'url': 'https://ec.europa.eu/health/sites/health/files/endocrine_disruptors/docs/cosmetic_1223_2009_regulation_en.pdf'
    },
    'aoec_asthmagens': {
        'title': 'AOEC Asthmagens',
        'description': 'Chemicals designated as asthmagens by the Association of Occupational and Environmental Clinics.',
        'url': 'http://www.aoecdata.org/'
    },
    'us_epa_tsca_chemicals_of_concern': {
        'title': 'US EPA TSCA Chemicals of Concern',
        'description': 'Chemical for which the United States Environmental Protection Agency has issued a Chemical of Concern Action Plan pursuant to the federal Toxic Substances Control Act.',
        'url': 'https://www.epa.gov/assessing-and-managing-chemicals-under-tsca/tsca-work-plan-chemicals'
    },
    'us_epa_ozone_depletors': {
        'title': 'US EPA Ozone Depletors',
        'description': 'Chemicals identified as a Class I or Class II OzoneDepleting Substance by the United States Environmental Protection Agency.',
        'url': 'https://www.epa.gov/ozone-layer-protection/ozone-depleting-substances'
    },
    'ny_doh_mcls': {
        'title': 'NY DOH MCLs',
        'description': 'Chemicals for which Maximum Contaminant Levels have been established and adopted in Tables 1, 3, 3A, and 7 of Subpart 5-1.52 of Title 10 of the New York Code of the Rules and Regulations (10 NYCRR Subpart 5-1.52).',
        'url': 'https://govt.westlaw.com/nycrr/Document/I3786df61c22411dd80c2c6f42ff0193c?viewType=FullText&originationContext=documenttoc&transitionType=CategoryPageItem&contextData=(sc.Default)'
    },
    'glwqa_chemicals_of_mutual_concern': {
        'title': 'GLWQA Chemicals of Mutual Concern',
        'description': 'Chemicals identified as Chemicals of Mutual Concern developed under the 2012 U.S./ Canada Great Lakes Water Quality Agreement (GLWQA) Annex 3.',
        'url': 'https://binational.net/wp-content/uploads/2016/09/PRP-160927-EN.pdf'
    },
    'ny_air_toxics': {
        'title': 'NY Air Toxics',
        'description': 'Chemicals identified as high toxicity air contaminants in Part 212 of Title 6 of the New York Codes of Rules and Regulations (6 NYCRR Subpart 212-2.2, as defined in Subpart 212-1.2 (b)(9)).',
        'url': 'https://govt.westlaw.com/nycrr/Document/If636da000a6611e58ce6a2a8a96ba7aa?viewType=FullText&originationContext=documenttoc&transitionType=CategoryPageItem&contextData=(sc.Default)&bhcp=1'
    }

}
