import { Injectable } from '@angular/core';
import { configureChains, createConfig } from "@wagmi/core";
import { arbitrum, mainnet, polygon } from "@wagmi/core/chains";
import { Web3Modal } from "@web3modal/html";
import { watchNetwork } from "@wagmi/core";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
  
} from "@web3modal/ethereum";



const chains = [arbitrum, mainnet, polygon];
const projectId = "6341eee952a19d517bbf1d0568b87b9a";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ chains,projectId }), 
  publicClient,
});

export const ethereumClient = new EthereumClient(wagmiConfig, chains);

// const { provider } = configureChains(chains, [
//   walletConnectProvider({ projectId })
// ]);

export const web3Modal = new Web3Modal(
  {
    projectId,
    walletImages: {
      safe: "https://pbs.twimg.com/profile_images/1566773491764023297/IvmCdGnM_400x400.jpg",
    },
  },
  ethereumClient
);



@Injectable({
  providedIn: 'root'
})
export class WalletConnectService {

  constructor() { }
}
