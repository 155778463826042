import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
//import { ServiceWorkerModule } from '@angular/service-worker';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { NgxMarkjsModule } from 'ngx-markjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
// component
import { AppComponent } from './app.component';
import { ErrorComponent } from './components/error/error.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PushNotificationsAccessComponent } from './components/push-notifications-access/push-notifications-access.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { LayoutComponent } from './layout/layout.component';
import { SharedModule } from './modules/shared/shared.module';




// export function appInit(appService: AppService) {
//   return () => appService.appInit();
// }



@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        ErrorComponent,
        LoadingComponent,
        PushNotificationsAccessComponent,

    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        // ServiceWorkerModule.register('/ngsw-worker.js', {
        //   enabled: true
        // }),
        NgxMarkjsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        AngularFireAuthModule,
        ReactiveFormsModule
        //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        StatusBar,
        SplashScreen
        // { provide: APP_INITIALIZER, useFactory: appInit, deps: [AppService], multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
